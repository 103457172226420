import React, {useState} from "react";
import Slider from "react-slick";
import Title from "../Comps/Title";

const Roadmap = () => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: false,
        dots: true,
        centerPadding: "100px",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        rows: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    dots: false
                }
            }
        ]
    };

    const [roadmap] = useState([
        {
            title: "Roadmap",
            desc: "Add a viewable screen for what is in-progress, done and upcoming",
            progress: 2,
        },
        {
            title: "Patch notes",
            desc: "Add a viewable screen for changes to project",
            progress: 2,
        },
        {
            title: "Socket 4.0 Upgrade",
            desc: "Update socket to 4.0 so the backend can make better use of rooms",
            progress: 2,
        },
        {
            title: "Snackbar",
            desc: "Add notification widgets to allow for user realtime feedback",
            progress: 2,
        },
        {
            title: "Upgrade React",
            desc: "Update react to latest version and make user of hooks and callbacks",
            progress: 2,
        },
        {
            title: "Upgrade React Router",
            desc: "Update react router to use the latest routing for better user navigation",
            progress: 2,
        },
        {
            title: "UI Overhaul",
            desc: "Implement Tailwind and re design everything ( haha what a funny idea )",
            progress: 1,
        },
        {
            title: "Themes",
            desc: "Now that tailwind is implemented across site themes can be added for users to spruce up their game",
            progress: 0,
        },
        {
            title: "Single Player Mode",
            desc: "Add a way for users to play a quiz on their own with different stats and rewards",
            progress: 0,
        },
        {
            title: "Better Stats",
            desc: "With the separation of single and multiplayer stats I will a better way to update the info stored ( this will probably be done at the same time as single )",
            progress: 0,
        },
        {
            title: "Quiz Editor",
            desc: "Re-work the quiz creator component so that it can be used for both creation and updating quiz with better management tools",
            progress: 0,
        },
        {
            title: "Quiz Management",
            desc: "Add options to make quiz private / delete for users and add admin controls",
            progress: 0,
        },
        {
            title: "Card Re-work",
            desc: "Rework the card drop mechanic from games so that they are ready for PvP",
            progress: 0,
        },
        {
            title: "Card Battles",
            desc: "Allow users to 1 on 1 with their collect cards and decks built",
            progress: 0,
        },
        {
            title: "Friends",
            desc: "Allow users to add friends",
            progress: 0,
        },
        {
            title: "Inventory",
            desc: "A better way to manage store bought items and equable's such as themes and card decks",
            progress: 0,
        },
        {
            title: "Store Expansion",
            desc: "Expand elements of the store for titles, frames, taunts and themes",
            progress: 0,
        },
        {
            title: "Payment Gateway",
            desc: "Allow users to like give me money for my shit",
            progress: 0,
        },
        {
            title: "About Me",
            desc: "Stroking My Ego",
            progress: 0,
        },
        {
            title: "Trading",
            desc: "Idk this one is up in the air but a way to swap items and whatnot",
            progress: 0,
        },
    ])

    const getProgressBorder = (num) => {
        switch (num) {
            case 0: return "!max-w-[calc(100vw-20px)] overflow-hidden rounded-lg shadow-lg"
            case 1: return "!max-w-[calc(100vw-20px)] overflow-hidden rounded-lg shadow-lg ring-2 ring-red-600"
            default: return "!max-w-[calc(100vw-20px)] overflow-hidden rounded-lg shadow-lg ring-2 ring-indigo-600"
        }
    }

    return (
        <>
            <Title title={"Roadmap"} desc={"Idk see whats going on init."} />
            <div className="slider-container place-items-center">
                <Slider {...settings}>
                    {roadmap.map((item) => (
                        <div key={item.title} className={getProgressBorder(item.progress)}>
                            <div className="flex flex-1 h-[50lvh] flex-col justify-between bg-white p-6">
                                <div className="flex-1">
                                    {item.progress === 0 && (
                                        <p className="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium bg-green-100 text-green-800">
                                            Up Coming
                                        </p>
                                    )}
                                    {item.progress === 1 && (
                                        <p className="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium bg-pink-100 text-pink-800">
                                            WIP
                                        </p>
                                    )}
                                    {item.progress === 2 && (
                                        <p className="inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium bg-indigo-100 text-indigo-800">
                                            Done
                                        </p>
                                    )}
                                    <div className="mt-2 block">
                                        <p className="text-xl font-semibold text-gray-900">{item.title}</p>
                                        <p className="mt-3 text-base text-gray-500">{item.desc}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default Roadmap;
